.appBar {
  margin-bottom: 50px;
}

.toolbar {
  justify-content: center;
}

.title {
  text-align: center;
}

.MuiBox-root .css-exd1zr {
  display: none !important;
}

.headerLink {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}